var validator;
jQuery(document).ready(function () {
    // validation
    $("#form-modal").validate({
    
        debug: true,
        rules:{
 
            name:{
                required: true,
                number: true
             },
 
             email:{
                required: true,
                email:true,
             },

             messege:{
                required: true,
             },

             phone:{
                required: true,
            },

            captcha: {
                required:true,
            },
        },
 
        messages:{
 
           name:{
                required:  "Вы не заполнили <br>поле",
           
             },

             email:{
                 required: "Вы не заполнили <br>поле",
                 email:"Введите корректный <br>e-maill!",
             },

             messege:{
                required: "Вы не заполнили <br>поле",
            },

            phone: {
                required: "Вы не заполнили <br>поле",
            },

            captcha: {
                required: "Вы не заполнили <br>поле",
            },
 
        },
        
     });

     $("#form").validate({
    
        debug: true,
        rules:{
 
            form__name:{
                required: true,
                number: true
             },
 
             form__email:{
                required: true,
                email:true,
             },

             form__messege:{
                required: true,
             },

             form__phone:{
                required: true,
            },

            form__captcha: {
                required:true,
            },
        },
 
        messages:{
 
            form__name:{
                required:  "Вы не заполнили <br>поле",
           
             },

             form__email:{
                 required: "Вы не заполнили <br>поле",
                 email:"Введите корректный <br>e-maill!",
             },

             form__messege:{
                required: "Вы не заполнили <br>поле",
            },

            form__phone: {
                required: "Вы не заполнили <br>поле",
            },

            form__captcha: {
                required: "Вы не заполнили <br>поле",
            },
 
        },
        
     });
     //открывание меню

    var burger = document.querySelector('.burger');
   
    $('.burger ').on('click', function () {
        $(this).toggleClass('active');
        $('.navigation').toggleClass('show');
    });


    $('.link').on('click', function () {
        if (screen.width < 768) {
            $('.burger').toggleClass('active');
            $('.navigation').toggleClass('show');
        }
    });
    
    // search

    var search = document.querySelector('.search__img'),
        searchInput = document.querySelector('.search__input');

        search.addEventListener('click', function(e) {
            searchInput.classList.toggle("search__input-toggle");

        });


        // scroll header

        jQuery(document).scroll(function () {
            scrollMenu();
        });
        
        function scrollMenu() {
            if ($(window).scrollTop() > 1) {
                $('.b-header').addClass('plate');
            } else {
                $('.b-header').removeClass('plate');
            }
        }

   
        
});